'use client'

import { cn } from "@/lib/utils"
import { motion } from "framer-motion"

interface AnimatedFeaturePointProps {
  children: React.ReactNode;
  index?: number;
  className?: string;
}

export const AnimatedFeaturePoint = ({ children, index = 0, className }: AnimatedFeaturePointProps) => (
  <motion.div
    className={cn("flex items-center gap-3", className)}
    initial={{ opacity: 0, x: -20 }}
    whileInView={{ opacity: 1, x: 0 }}
    viewport={{ once: true }}
    transition={{ duration: 0.3, delay: 0.1 + (index * 0.4) }}
  >
    {children}
  </motion.div>
)

interface AnimatedFeatureImageProps {
  children: React.ReactNode;
}

export const AnimatedFeatureImage = ({ children }: AnimatedFeatureImageProps) => (
  <motion.div
    className="glass-panel p-4 rounded-2xl aspect-square overflow-hidden group relative bg-white transform transition-transform duration-300 group-hover:scale-105"
    initial={{ rotateY: 90 }}
    whileInView={{ rotateY: 0 }}
    viewport={{ once: true }}
    transition={{ duration: 0.3, ease: "easeOut" }}
  >
    {children}
  </motion.div>
) 