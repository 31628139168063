'use client'

import { cn } from "@/lib/utils"

interface AnimatedGradientTextProps {
  children: React.ReactNode
  className?: string
  gradientColors?: {
    from: string
    via: string
    to: string
  }
}

export function AnimatedGradientText({
  children,
  className,
  gradientColors = {
    from: "from-purple-400",
    via: "via-pink-300",
    to: "to-blue-400"
  }
}: AnimatedGradientTextProps) {
  return (
    <span
      className={cn(
        "bg-gradient-to-r bg-clip-text text-transparent animate-[pulse_3s_ease-in-out_infinite]",
        gradientColors.from,
        gradientColors.via,
        gradientColors.to,
        className
      )}
    >
      {children}
    </span>
  )
} 