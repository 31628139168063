'use client'

import { cn } from "@/lib/utils"
import { motion } from "framer-motion"

interface AnimatedComponentProps {
  children: React.ReactNode;
  className?: string;
}

export const AnimatedHeading = ({ children, className }: AnimatedComponentProps) => (
  <motion.h1
    className={cn("heading-xl mb-6 text-left", className)}
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
  >
    {children}
  </motion.h1>
)

export const AnimatedParagraph = ({ children, className }: AnimatedComponentProps) => (
  <motion.p
    className={cn("text-xl text-neutral-600 mb-8 text-left", className)}
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5, delay: 0.2 }}
  >
    {children}
  </motion.p>
)

export const AnimatedButtons = ({ children, className }: AnimatedComponentProps) => (
  <motion.div
    className={cn("flex flex-wrap gap-4", className)}
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5, delay: 0.4 }}
  >
    {children}
  </motion.div>
)

export const AnimatedImage = ({ children, className }: AnimatedComponentProps) => (
  <motion.div
    className={cn("flex-1", className)}
    initial={{ opacity: 0, scale: 0.95 }}
    animate={{ opacity: 1, scale: 1 }}
    transition={{ duration: 0.5, delay: 0.6 }}
  >
    {children}
  </motion.div>
) 