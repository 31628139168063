'use client'

import { cn } from "@/lib/utils"
import { ButtonHTMLAttributes } from 'react'

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
}

export const TryForFreeButton = ({ className, ...props }: ButtonProps) => (
  <button
    onClick={() => {
      const event = new CustomEvent('openContactForm')
      window.dispatchEvent(event)
    }}
    className={cn("button-primary", className)}
    {...props}
  >
    Try for free
  </button>
)

export const LearnMoreButton = ({ className, ...props }: ButtonProps) => (
  <button
    className={cn(
      "px-6 py-3 text-neutral-600 hover:text-primary transition-colors",
      className
    )}
    {...props}
  >
    Learn more
  </button>
) 