'use client'

import { Component, type PropsWithChildren } from 'react'

interface Props extends PropsWithChildren {
  sectionType: string
}

interface State {
  hasError: boolean
  error: Error | null
}

export class SectionErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false, error: null }
  }

  static getDerivedStateFromError(error: Error): State {
    return { hasError: true, error }
  }

  componentDidCatch(error: Error) {
    console.error(`Error in section ${this.props.sectionType}:`, error)
  }

  render() {
    if (this.state.hasError) {
      // You might want to render a more sophisticated error UI in production
      if (process.env.NODE_ENV === 'development') {
        return (
          <div className="p-4 my-4 bg-red-50 border border-red-200 rounded-lg">
            <h3 className="text-red-800 font-semibold mb-2">
              Error in section: {this.props.sectionType}
            </h3>
            <pre className="text-sm text-red-700 whitespace-pre-wrap">
              {this.state.error?.message}
            </pre>
          </div>
        )
      }
      // In production, we might want to silently fail or show a minimal error
      return null
    }

    return this.props.children
  }
} 