"use client"

import { cn } from "@/lib/utils"
import { formatMargins } from "@/sanity/lib/format-margins"
import type { StatsSection } from "@/sanity/types"
import { useInView } from "framer-motion"
import { useRef } from "react"
import CountUp from "react-countup"

export function Stats({
  sectionId,
  heading,
  description,
  stats,
  margins
}: Omit<StatsSection, '_type' | '_key'>) {
  const ref = useRef(null)
  const isInView = useInView(ref, { once: true })

  return (
    <section data-section="stats" id={sectionId} className={cn(!margins && "py-24", "scroll-mt-32", formatMargins(margins))} ref={ref}>
      <div className="container-padding">
        <div className="max-w-screen-lg mx-auto">
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold mb-4">{heading}</h2>
            <p className="text-neutral-600 max-w-2xl mx-auto">
              {description}
            </p>
          </div>

          <div className="grid md:grid-cols-3 gap-8">
            {stats?.map((stat, index) => (
              <div
                key={`stat-${stat._key}-${index}`}
                className="glass-panel rounded-2xl p-8 hover:scale-105 transition-transform duration-300 animate-fade-up"
                style={{
                  animationDelay: "150ms",
                  opacity: 0
                }}
              >
                <div className="flex flex-col items-center justify-center h-full">
                  <p className="text-6xl font-bold mb-4 text-primary">
                    {isInView && stat.value && (
                      <CountUp
                        start={0}
                        end={stat.value}
                        duration={2}
                        prefix={stat.prefix ?? undefined}
                        suffix={stat.suffix ?? undefined}
                      />
                    )}
                  </p>
                  <p className="text-lg text-neutral-600">{stat.label}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
} 