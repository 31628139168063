'use client'

import { cn } from "@/lib/utils"
import { urlFor } from "@/sanity/lib/client"
import { formatMargins } from "@/sanity/lib/format-margins"
import type { HeroVideoSection } from "@/sanity/types"
import { motion } from "framer-motion"
import { AnimatedGradientText } from "../ui/animated-gradient-text"

export function HeroVideo({
  sectionId,
  videoUrl,
  posterImage,
  title,
  text,
  showArrow,
  margins
}: Omit<HeroVideoSection, '_type' | '_key'>) {
  return (
    <section
      data-section="heroVideo"
      id={sectionId}
      className={cn("relative w-full h-screen", formatMargins(margins))}
    >
      <div className="absolute inset-x-0 w-full h-full">
        <video
          className="absolute inset-0 w-full h-full object-cover"
          autoPlay
          muted
          loop
          playsInline
          poster={posterImage ? urlFor(posterImage).url() : undefined}
        >
          <source src={videoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        <div className="absolute inset-0 bg-black/40" />

        <div className="absolute inset-0 flex items-center justify-center">
          <div className="text-center text-white px-6">
            {title && (
              <motion.h1
                className="text-4xl md:text-6xl font-bold mb-4"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                {title.prefix}{" "}
                {title.highlight && (
                  <AnimatedGradientText
                    gradientColors={{
                      from: "from-[#FF1CF7]",
                      via: "via-[#E01EF7]",
                      to: "to-[#b249f8]"
                    }}
                  >
                    {title.highlight}
                  </AnimatedGradientText>
                )}
              </motion.h1>
            )}
            {text && (
              <motion.p
                className="text-xl md:text-2xl max-w-2xl mx-auto"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2, duration: 0.5 }}
              >
                {text}
              </motion.p>
            )}
          </div>
        </div>

        {showArrow && (
          <motion.div
            className="absolute bottom-8 left-1/2 -translate-x-1/2"
            animate={{ y: [0, 10, 0] }}
            transition={{ repeat: Infinity, duration: 1.5 }}
          >
            <svg
              className="w-8 h-8 text-white"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M19 14l-7 7m0 0l-7-7m7 7V3"
              />
            </svg>
          </motion.div>
        )}
      </div>
    </section>
  )
} 