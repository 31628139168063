import { cn } from '@/lib/utils'
import type { TwoColumnSection } from '../types'

type Margins = NonNullable<TwoColumnSection['margins']>

export function formatMargins(margins?: Margins | null): string {
  if (!margins) return ''

  switch (margins.type) {
    case 'all':
      return margins.all === 'none' ? '' : margins.all || ''
    case 'axis':
      return cn(
        margins.vertical === 'none' ? '' : margins.vertical,
        margins.horizontal === 'none' ? '' : margins.horizontal
      )
    case 'individual':
      return cn(
        margins.top === 'none' ? '' : margins.top,
        margins.right === 'none' ? '' : margins.right,
        margins.bottom === 'none' ? '' : margins.bottom,
        margins.left === 'none' ? '' : margins.left
      )
    default:
      return ''
  }
} 